import XLSX from 'xlsx'
import moment from 'moment'

export function ExceltoJson(excel, keyData = null) {
  // var data = new Uint8Array(excel);

  /* Call XLSX */
  var workbook = XLSX.read(excel, {
    type: 'binary',
  })
  let XL_object = []
  workbook.SheetNames.forEach(function (sheetName) {
    XL_object.push(
      XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName], {
        header: 1,
        blankRows: false,
      })
    )
  })
  let res = XL_object.length > 0 ? XL_object[0].filter((x) => x.length > 0) : []
  console.log(res);
  // console.log("ExceltoJson -> res[0]", res[0])
  let data = [...res]
  data.shift()
  data = data.map((e) => {
    let obj = {}
    e.forEach((k, i) => {
      if (keyData && keyData[res[0][i]]) {
        obj[keyData[res[0][i]]] = k
      } else {
        obj[res[0][i]] = k
      }
    })
    return obj
  })
  return {
    headers: res[0],
    data: data,
  }
}

export function ValidateType(item, ruleType = [], ruleRequired = []) {
  let err = {}
  Object.keys(item).forEach((e) => {
    if (ruleType.indexOf(e) > -1) {
      if (item[e] && isNaN(item[e])) {
        err[e] = 'Không đúng định dạng'
      }
    }
  })
  ruleRequired.forEach((e) => {
    if (Object.keys(item).indexOf(e) < 0) {
      err[e] = `${e} không được để trống`
    }
  })
  return err
}
export function ValidateDate(dd, mm, yy) {
  return moment(
    `${dd ? dd : 1}/${mm ? mm : 1}/${yy ? yy : 2004}`,
    'D/M/YYYY',
    true
  ).isValid()
}
export function ValidateDateOfString(str) {
  if (!str) {
    return true
  }
  str = str.toString().trim()
  if (moment(str, 'YYYY', true).isValid()) {
    return true
  }
  if (moment(str, 'M/YYYY', true).isValid()) {
    return true
  }
  if (moment(str, 'D/M/YYYY', true).isValid()) {
    return true
  }
  if (moment(str, 'D/M', true).isValid()) {
    return true
  }
  if (moment(str).isValid()) {
    return true
  }

  return false
}

// const XLSX = require('xlsx');
export function JsontoExcel(object, sheetName) {
  console.log("🚀 ~ file: Excel.js ~ line 90 ~ JsontoExcel ~ sheetName", sheetName)
  console.log("🚀 ~ file: Excel.js ~ line 90 ~ JsontoExcel ~ object", object)
  // var ws = XLSX.utils.json_to_sheet(object, {
  //   Header: Object.keys(object[0])
  // });
  // let wscols = [];
  // Object.keys(object[0]).forEach((key, i) => {
  //   wscols.push({
  //     wpx: 100
  //   })
  // })
  // /* add to workbook */
  // ws['!cols'] = wscols;
  // ws['A1'].s = {
  //   fill: {
  //     fgColor: {
  //       rgb: "FFFFAA00"
  //     }
  //   },
  //   alignment: {
  //     wrapText: true
  //   },
  //   border: {
  //     bottom: {
  //       style: "thick",
  //       color: "red"
  //     }
  //   }
  // }
  // var wb = XLSX.utils.book_new();
  // XLSX.utils.book_append_sheet(wb, ws, "People");
  // /* generate an XLSX file */
  // XLSX.writeFile(wb, `${sheetName}.xlsx`)
}
