<template>
  <PageListLayout noFilter>
    <template slot="header_right">
      <v-list-item three-line>
        <v-layout align-center>
          <v-img
            max-height="150"
            max-width="150"
            :src="BaoCaoPic"
            class="mr-4"
          ></v-img>
          <v-list-item-content>
            <v-list-item-title class="headline mb-1 black--text">
              BÁO CÁO HỆ THỐNG</v-list-item-title
            >
            <v-list-item-subtitle
              >Báo cáo tổng hợp theo thời gian</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-layout>
      </v-list-item>
    </template>
    <!-- <template slot="side-right">
      <input type="file" @change="excelFile" />
    </template> -->
    <v-row>
      <v-col cols="3" v-for="(item, index) in baocaos" :key="index">
        <v-card
          class="mx-auto"
          :color="item.color"
          dark
          max-width="400"
          height="250"
          @click="xemBaoCao(item.path)"
        >
          <v-layout column>
            <v-card-title>
              <v-icon large left> mdi-book </v-icon>
              <span class="title font-weight-light"
                >Báo cáo {{ "0" + (index + 1) }}</span
              >
            </v-card-title>

            <v-layout
              column
              style="flex: 1"
              align-center
              justify-center
              class="pt-7"
            >
              <v-icon size="60">{{ item.icon }}</v-icon>
              <div class="pt-2" style="font-size: 18px; font-weight: bold">
                {{ item.name }}
              </div>
            </v-layout>
          </v-layout>
        </v-card>
      </v-col>
    </v-row>
  </PageListLayout>
</template>

<script>
import BaoCaoPic from "../../assets/images/baocao.svg";
import { ExceltoJson } from "@/utils/Excel";
export default {
  data: () => ({
    BaoCaoPic,
    baocaos: [
      {
        name: "Báo cáo kết quả kinh doanh",
        color: "#00897B",
        icon: "mdi-account-check",
        path: "kinhdoanh" ,
      },
      {
        name: "Báo cáo Tăng trưởng thị phần",
        icon: "mdi-file-document",
        color: "#26c6da",
        path: "thiphan",
      },
      {
        name: "Báo cáo Khách hàng trọng tâm",
        color: "#43A047",
        icon: "mdi-account-multiple",
        path: "khachhangtrongtam",
      },
      {
        name: "Báo cáo Khiếu nại",
        color: "#01579B",
        path: "khieunai",
        icon: "mdi-calendar-range",
      },
      {
        name: "Báo cáo Chứng từ - huỷ hàng",
        color: "#F57F17",
        path: "huyhang",
        icon: "mdi-file",
      },
      // {
      //   name: "Báo Cáo thai sản",
      //   color: "#F57F17",
      //   id: 1,
      //   icon: "mdi-gender-male-female",
      // },
      // {
      //   name: "Báo Cáo Tổng Hợp Nhân Sự",
      //   color: "#4A148C",
      //   icon: "mdi-account-multiple",
      //   id: 1,
      // },
      // {
      //   name: "Báo Cáo Bảo Hiểm Xã Hội",
      //   color: "#C2185B",
      //   icon: "mdi-heart-pulse",
      //   id: 1,
      // },
    ],
  }),
  methods: {
    excelFile(e) {
      let rdr = new FileReader();
      let file = e.target.files;
      rdr.onload = (e) => {
        let res = ExceltoJson(e.target.result);
        console.log("🚀 ~ file: index.vue ~ line 119 ~ excelFile ~ res", res);
      };
      rdr.readAsBinaryString(file[0]);
    },
    xemBaoCao(path) {
      this.$router.push("/baocao/" + path);
    },
  },
};
</script>

<style>
</style>